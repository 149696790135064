import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Container, Typography, Grid, Paper, AppBar, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { FixedSizeList } from "react-window";
import SensorsIcon from '@mui/icons-material/Sensors';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';


const Row = ({ data, style, setOpen, id, setId, setEmail, setIsActive}) => {
    return (
      <div style={style}>
        <ListItem >
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={1} lg={1}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#3f51b5' }}>
                    {data.is_admin ? <SupervisorAccountIcon /> : <PersonIcon />}
                  </Avatar>
                </ListItemIcon>
              </Grid>

              <Grid item xs={1} lg={1}>
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                  {data.is_admin ? "Admin" : "User"}
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4}>
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                {data.last_name} {data.name} {/* 在大写字母前分隔 */}
                </Typography>
              </Grid>
              <Grid item xs={4} lg={4}>
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                  {data.email}
                </Typography>
              </Grid>

              <Typography variant="h6" style={{ flexGrow: 1 }}></Typography> {/* 添加 flexGrow 以推送内容到右侧 */}
              {!data.is_admin && 
              <Grid item xs={1} lg={1}>
                <IconButton color="inherit" disabled={data.is_active} onClick={()=>{setOpen(true); setId(data.sub); setEmail(data.email); setIsActive(!data.is_active)}}>
                  {data.is_active ? <CheckCircleOutlineIcon style={{color: 'grey'}}/> : <CheckCircleOutlineIcon style={{color: 'green'}}/>}
                </IconButton>
              </Grid>}
              {!data.is_admin && 
              <Grid item xs={1} lg={1}>
                <IconButton color="inherit" disabled={!data.is_active} onClick={()=>{setOpen(true); setId(data.sub); setEmail(data.email); setIsActive(!data.is_active)}}>
                  {!data.is_active ? <NotInterestedIcon style={{color: 'grey'}}/> : <NotInterestedIcon style={{color: 'red'}}/>}
                </IconButton>
              </Grid>}
            </Grid>
          </Paper>
        </ListItem>
      </div>
    );
};


const UserAdmin = () => {
  const [userData, setUserData] = useState({});
  const [searchName, setSearchName] = useState("");
  const [open, setOpen] = useState(false); // 添加状态管理
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [is_active, setIsActive] = useState("");
  const containerRef = useRef(null);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchUserList = async () => {
    try {
      const response = await fetch('/api/user/list');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([userId, userData]) => {
        setUserData(prevData => ({
          ...prevData,
          [userId]: { ...userData } // 更新 SPS 数据
        }));
      });
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUpdateUser = async () => {
    try {
      const response = await fetch('/api/user/enable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, email , is_active}),
      })
      const data = await response.json();
      if (data.success){
        setSeverity("success");
        setSnackbarMessage(data.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(response.error);
      }
      setSnackbarOpen(true);
      setOpen(false);
      fetchUserList();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  const filteredRecords = searchName 
    ? Object.entries(userData)
        .filter(([userId, data]) => (data.email.includes(searchName)))
    : Object.entries(userData); // 按名称排序

  return (
    <Container ref={containerRef}>
      <CssBaseline />
      <Box my={4} mx="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth="80%">
      <Typography variant="h4" style={{ marginBottom: '16px' }}>User Admin</Typography> {/* 修改为 Dashboard 标题 */}
      
      <TextField
        label="Serach by Email"
        onChange={(e) => setSearchName(e.target.value)}
        fullWidth
        style={{ marginBottom: '16px' }} // 添加底部间距
      />

      <FixedSizeList
        height={375}
        itemSize={75} // 每个项的高度
        itemCount={filteredRecords.length} // 项的数量 + 1 for header
        width="100%" // 列表宽度
        onMouseDown={(e) => e.preventDefault()} // 禁用鼠标事件
        onMouseLeave={(e) => e.preventDefault()} // 禁用触摸事件
      >
        {({ index, style }) => {
          const [id, data] = filteredRecords[index]; // 获取传感器ID和数据
          return (
            <Row id={data['deviceId']} data={data} style={style} setOpen={() => setOpen(true)} key={id} setId={setId} setEmail={setEmail} setIsActive={setIsActive}/>
          );
        }}

      </FixedSizeList>
    </Box>
    <Dialog open={open} onClose={() => {setOpen(false)}} fullWidth maxWidth="xs"> {/* 使用 Dialog 替代 Modal */}
        <DialogContent>
        <DialogTitle style={{ marginTop: '0px', marginBottom: '16px', textAlign: 'center' }}>{!is_active ? "Disabling" : "Enabling"} : {email}</DialogTitle> {/* 添加文本居中对齐 */}
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-between', margin: '0px 16px 16px 16px'}}>
          <Button variant="contained" onClick={() => {fetchUpdateUser()}}>COMFIRM</Button>
          <Button variant="contained" onClick={() => {setEmail(""); setOpen(false)}}>CANCEL</Button>
        </DialogActions>
    </Dialog>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
    </Snackbar>
    </Container>
  );
};

export default UserAdmin;