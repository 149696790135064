import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // 更新导入
import { Divider,Avatar, Container, Typography, Grid, Paper, AppBar, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Menu, MenuItem } from '@mui/material';
import { FixedSizeList } from "react-window"; // 导入 FixedSizeList
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // 导入菜单图标
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SensorsIcon from '@mui/icons-material/Sensors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { format } from 'date-fns';

function MainAppBar({ userData }) {

    const notification = userData.notification ? userData.notification.sort((a, b) => new Date(b.date) - new Date(a.date)) : [];

    const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
    const [anchorEl, setAnchorEl] = useState(null); // 新增状态以控制下拉菜单
    const [anchorE2, setAnchorE2] = useState(null); // 新增状态以控制下拉菜单
    const [menuOptionsOpen, setMenuOptionsOpen] = useState(false); // 新增状态以控制下拉菜单
    const navigate = useNavigate(); // 创建 navigate 函数

    const fetchUserLogout = async () => {
        try {
            const response = await fetch('/api/user/logout');
            if(response.ok){
            window.location.href = '/login';
            }
        } catch (error) {
            console.error('Error fetching last record:', error);
        }
    };
    
    const handleMenuClickOpen = () => {
    setMenuOpen(!MenuOpen);
    };

    const handleMenuClickClose = () => {
    setMenuOpen(false);
    };

    const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // 设菜单锚点
    };

    const handleMenuClose = () => {
    setAnchorEl(null); // 关闭菜单
    };
    
    const handleNotificationClick = (event) => {
        setAnchorE2(event.currentTarget); // 设菜单锚点
    };


    const handleNotificationClose = () => {
        setAnchorE2(null); // 关闭菜单
    };

    return (
        <AppBar position="static" >
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography> {/* 添加 flexGrow 以推送内容到右侧 */}
          <IconButton color="inherit" onClick={handleNotificationClick}>
            <NotificationsIcon />
          </IconButton> 
          <Typography variant="h6" style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
            <img 
              src={userData.picture} 
              alt="User Avatar" 
              style={{ width: '40px', borderRadius: '50%', marginRight: '8px', cursor: 'pointer' }} // 添加光标样式
              onClick={handleMenuOpen} // 点击头像打开菜单
            />
          </Typography>
        </Toolbar>
        
        <Drawer variant="permanent" anchor="left" open={MenuOpen} onClose={handleMenuClickClose} >
        {MenuOpen ? (
            <Box width={200} role="presentation">
            <List>
                <ListItem>
                <IconButton color="inherit" onClick={handleMenuClickOpen} style={{padding: '0px'}}>
                    <MenuIcon />
                </IconButton>
                <ListItemText primary="Hide" style={{paddingLeft: '30px'}}/>
                </ListItem>
                <ListItem button onClick={() => navigate('/dashboard')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <DashboardIcon /> {/* 添加 Dashboard 图标 */}
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                </ListItem>
                {userData.is_admin &&
                <ListItem button onClick={() => navigate('/edit')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <SensorsIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                <ListItemText primary="Edit" />
                </ListItem>
                }
                {userData.is_admin &&
                <ListItem button onClick={() => navigate('/users')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <GroupIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                <ListItemText primary="Users" />
                </ListItem>
                }
                <ListItem button onClick={() => navigate('/trends')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <SignalCellularAltIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                <ListItemText primary="Trends" />
                </ListItem>
            </List>
            </Box>

        ):(
            <Box width={60} role="presentation" overflow="hidden">
            <List>
                <ListItem>
                <IconButton color="inherit" onClick={handleMenuClickOpen} style={{padding: '0px'}}>
                    <MenuIcon />
                </IconButton>
                </ListItem>
                <ListItem button onClick={() => navigate('/dashboard')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <DashboardIcon /> {/* 添加 Dashboard 图标 */}
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                </ListItem>
                {userData.is_admin &&
                <ListItem button onClick={() => navigate('/edit')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <SensorsIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                <ListItemText primary="Edit" />
                </ListItem>
                }
                {userData.is_admin &&
                <ListItem button onClick={() => navigate('/users')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <GroupIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                </ListItem>
                }
                <ListItem button onClick={() => navigate('/trends')}> {/* 使用 navigate 替代 window.location.href */}
                <ListItemIcon>
                    <SignalCellularAltIcon /> {/* 添加 Login 图标 */}
                </ListItemIcon>
                </ListItem>
            </List>
            </Box>

        )}

        <Menu 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl) && !menuOptionsOpen} 
        onClose={handleMenuClose} 
        PaperProps={{ style: { width: 250, borderRadius: '16px' } }} // 设置固定宽度并添加圆角
        >
        <ListItemIcon style={{paddingTop: '3px',paddingBottom: '10px',paddingLeft: '5px',paddingRight: '10px'}}>
        <img 
            src={userData.picture} 
            alt="User Avatar" 
            style={{ width: '50px', borderRadius: '50%', marginLeft: '8px',marginRight: '8px'}} // 添加光标样式
        />
        {userData.name} <br /> {/* 添加换行 */}
        @{userData.email.split('@')[0]}
        </ListItemIcon>
        <Divider /> {/* 添加分隔线 */}
        <MenuItem onClick={() => navigate('/settings')}>
            <ListItemIcon>
            <SettingsIcon /> {/* 添加设置图标 */}
            </ListItemIcon>
            Settings
        </MenuItem> {/* 添加设置选项 */}
        

        {false && (<MenuItem onClick={() => setMenuOptionsOpen(true)} anchorEl={anchorEl}> {/* 修为使用 anchorEl */} {/* 新增打开主题选项的逻辑 */}
            <ListItemIcon>
            <Brightness4Icon /> {/* 添加主题切换图标 */}
            </ListItemIcon>
            Dark Mode
        </MenuItem>)}
        
        <Divider /> {/* 添加分隔线 */}
        <MenuItem onClick={fetchUserLogout}>
            <ListItemIcon>
            <LogoutIcon /> {/* 添加登出图标 */}
            </ListItemIcon>
            Logout
        </MenuItem> {/* 添加登出选项 */}
        </Menu>

        <Menu 
        anchorEl={anchorE2} 
        open={Boolean(anchorE2)} 
        onClose={handleNotificationClose} 
        PaperProps={{ style: { width: 300, borderRadius: '16px' } }} // 设置固定宽度并添加圆角
        >
        {notification?.map((item, index) =>
            <MenuItem key={index} onClick={() => navigate('/dashboard', { state: { notification: item.name } })}>
            <ListItemIcon>
                <NotificationsIcon />
            </ListItemIcon>
            <Typography variant="body1">
                {item.notificationtype} <br/> {item.name} <br /> {new Date(item.date).toLocaleString()}<br/>
            </Typography>
            </MenuItem>
        )}
        </Menu>
        
      </Drawer>
      </AppBar>
    )
}
   
export default MainAppBar; // 导出 AppBar 组件
