import React, { useEffect, useState, useRef } from 'react';
import { Button, Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Stack, Typography } from '@mui/material';

const scdDatalist = [
  ["scd",0,1000],
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ]

const sfaDatalist = [
  ["sfa",0,1],
  ["sfa",1,100],
  ["sfa",2,100],
]

const spsDatalist = [
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",4,100],
  ["sps",5,100],
  ["sps",6,100],
  ["sps",7,100],
  ["sps",8,100],
  ["sps",9,100],
]
  
  const scdDataLabels = [
    "Battery [V]",
    "Co2 [ppm]",
    "Temperature [°C]",
    "Humidity [%]",
  ];

  const sfaDataLabels = [
    "HCHO [ppb]",
    "Temperature [°C]",
    "Humidity [%]",
  ];

  const spsDataLabels = [
    "PM 1.0 [µg/m³]",
    "PM 2.5 [µg/m³]",
    "PM 4.0 [µg/m³]",
    "PM 10.0 [µg/m³]",
    "NC 0.5 [#/cm³]",
    "NC 1.0 [#/cm³]",
    "NC 2.5 [#/cm³]",
    "NC 4.0 [#/cm³]",
    "NC 10.0 [#/cm³]",
    "Typical particle size [µm]"
  ];

const CusDataTable = ({ sensorId, startDate, endDate, sensorData, setSeverity, setSnackbarOpen, setSnackbarMessage , display}) => {

    const tableContainerRef = useRef(null); // 创建引用
    const [scdTableData, setScdTableData] = useState([]); // 初始化 chartData
    const [spsTableData, setSpsTableData] = useState([]); // 初始化 chartData
    const [sfaTableData, setSfaTableData] = useState([]); // 初始化 chartData
    const [timestamps, setTimestamps] = useState([]);
    const [index, setIndex] = useState(1);
    const [wssData,setWssData] = useState([]);

    const exportToCSV = () => {
      const csvRows = [];
      const headers = ['TimeStamp',...scdDataLabels.map(label => label.split("[")[0]), ...spsDataLabels.map(label => label.split("[")[0]), ...sfaDataLabels.map(label => label.split("[")[0])];

      const newTimestamps = Object.keys(wssData['scd']);
      
      csvRows.push(headers.join(','));

      newTimestamps.forEach((timestamp, rowIndex) => {
          if(wssData['sps'][timestamp]){
            const row = [
              new Date(timestamp).toLocaleString('zh-CN', { hour12: false }),
              ...[wssData['scd'][timestamp].map((data,index) => data / scdDatalist[index][2])],
              ...[wssData['sps'][timestamp].map((data,index) => data / spsDatalist[index][2])],
              ...[wssData['sfa'][timestamp].map((data,index) => data / sfaDatalist[index][2])],
            ];
            csvRows.push(row.join(','));

          } else {
            const row = [
              new Date(timestamp).toLocaleString('zh-CN', { hour12: false }),
              ...[wssData['scd'][timestamp].map((data,index) => data / scdDatalist[index][2])],
              ...[wssData['sps'][timestamp].map((data,index) => data / spsDatalist[index][2])],
              ...[wssData['sfa'][timestamp].map((data,index) => data / sfaDatalist[index][2])],
            ];
          csvRows.push(row.join(','));
          }
      });

      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', sensorData[sensorId]['name'] + "_" + sensorId.toUpperCase() + "_" + startDate + "_" + endDate + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    

    const fetchData = async () => {
      try {
        if(endDate === null || startDate === null){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Time range cannot be empty");
          return;
        }
        if(endDate < startDate){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("End time cannot be earlier than start time");
          return;
        }

        const adjustedEndDate = new Date(new Date(endDate).setHours(23, 59, 59)).getTime();
        const adjustedStartDate = new Date(new Date(startDate).setHours(0, 0, 0)).getTime();
        const url = "/api/history?repeator_id=" + sensorId + "&start_time=" + adjustedStartDate + "&end_time=" + adjustedEndDate;
        const response = await fetch(url);
        const data = await response.json();

        if(data.length === 0){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Over time range");
          return;
        }
        
        setWssData(data);
        setSeverity("success");
        setSnackbarOpen(true);
        setSnackbarMessage("Data fetched successfully");
        const newTimestamps = Object.keys(data['scd']).slice((index - 1) * 10, index * 10);
        setTimestamps(newTimestamps); // 追加时间戳
        setScdTableData(newTimestamps.map(timestamp => data['scd'][timestamp])); // 追加 scd 数据
        setSpsTableData(newTimestamps.map(timestamp => data['sps'][timestamp])); // 追加 sps 数据
        setSfaTableData(newTimestamps.map(timestamp => data['sfa'][timestamp])); // 追加 sfa 数据
      } catch (error) {
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Error fetching data");
          console.error('Error fetching chart data:', error);
      }
    };

    useEffect(() => {
      if (sensorId) {
        fetchData();
      }
    }, [display]);

    const updateData = () => {
      const newTimestamps = Object.keys(wssData['scd']).slice((index - 1) * 10, index * 10);
      setTimestamps(newTimestamps); // 追加时间戳
      setScdTableData(newTimestamps.map(timestamp => wssData['scd'][timestamp])); // 追加 scd 数据
      setSpsTableData(newTimestamps.map(timestamp => wssData['sps'][timestamp])); // 追加 sps 数据
      setSfaTableData(newTimestamps.map(timestamp => wssData['sfa'][timestamp])); // 追加 sfa 数据
    }

    useEffect(() => {
      if (wssData.length !== 0) {
        console.log(index,)
        updateData()
      }
    }, [index]);

  
    return (
      <Stack direction="column" spacing={2} paddingBottom={2} alignItems="center">
        {scdTableData.length === 0 && spsTableData.length === 0 && (
          <Typography>No data found</Typography>
        )}
        {scdTableData.length !== 0 && spsTableData.length !== 0 && sfaTableData.length !== 0 && (
        <TableContainer ref={tableContainerRef} style={{ overflowY: 'auto',overflowX: 'auto',height:'600px',width:'1050px'}}> {/* 添加引用 */}
          <Table
          borderAxis="bothBetween"
          stripe="odd"
          hover
          sx={{
            '& tr > *': { // 修改为所有单元格
              height: '10px', // 设置单元格高度
              '&:first-child': {
                position: 'sticky',
                left: 0,
                bgcolor: '#121212', // 设置背景颜色为黑色
              },
            },
          }}>
          <TableBody >
            <TableRow>
            <TableCell sx={{ padding: 1 }} style={{ border: '0px solid black',minWidth: '190px'}}> TimeStamp </TableCell>
              {timestamps.map((timestamp, index, _array) => (
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid black' ,minWidth: '80px'}} key={index}>{new Date(_array[index]).toLocaleString('zh-CN', { hour12: false })}</TableCell>
              ))}
            </TableRow>
          </TableBody>
          <TableBody>
          {scdDataLabels.map((label, index) => (
            <TableRow key={index}>
              <TableCell sx={{ padding: 1 }} style={{ border: '0px solid black',minWidth: '190px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
              {scdTableData.map((data, _index, _array) => (
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid black' ,minWidth: '80px'}}>{_array[_index][scdDatalist[index][1]] / scdDatalist[index][2]}</TableCell>
              ))}
            </TableRow >
          ))}
          </TableBody>
          <TableBody>
            {sfaDataLabels.map((label, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: 1 }} style={{ border: '0px solid black', minWidth: '190px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
                {sfaTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid black' , minWidth: '80px'}}>{_array[_index] ? _array[_index][sfaDatalist[index][1]] / sfaDatalist[index][2]: 'null'}</TableCell>
                ))}
              </TableRow >
            ))}
          </TableBody>
          <TableBody>
            {spsDataLabels.map((label, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: 1 }} style={{ border: '0px solid black', minWidth: '190px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
                {spsTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid black' , minWidth: '80px'}}>{_array[_index] ? _array[_index][spsDatalist[index][1]] / spsDatalist[index][2]: 'null'}</TableCell>
                ))}
              </TableRow >
            ))}
          </TableBody>
          </Table>
      </TableContainer>
      )}
      
      {scdTableData.length !== 0 && spsTableData.length !== 0 && sfaTableData.length !== 0 && (
      <Stack direction="column" spacing={2} alignItems="center" marginTop={2}>
        <Stack direction="row" spacing={2} alignItems="center" marginTop={2}>
          <Button variant="contained" onClick={() => setIndex(prevIndex => prevIndex - 1)} disabled={index === 1}>Prev</Button>
          <Button variant="contained" onClick={() => setIndex(prevIndex => prevIndex + 1)} disabled={index === (Math.floor(Object.keys(wssData['scd']).length / 10) + 1)}>Next</Button>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>Rendered Records: {(index-1) * 10 + 1} - {index * 10} of {Object.keys(wssData['scd']).length}</Typography>
          <Typography>Start Time: {new Date(Object.keys(wssData['scd'])[0]).toLocaleString('zh-CN', { hour12: false })}</Typography>
          <Typography>End Time: {new Date(Object.keys(wssData['scd'])[Object.keys(wssData['scd']).length - 1]).toLocaleString('zh-CN', { hour12: false })}</Typography>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Button variant="contained" onClick={exportToCSV}>Export</Button>
        </Stack>
      </Stack>
      )}
      </Stack>
    );
  };

export default CusDataTable;