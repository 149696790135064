import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Container, Typography, Grid, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import { FixedSizeList } from "react-window";
import HistoryChart from '../components/HistoryChart';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';


const Row = ({ data, className, style , onClick, lastUpdated }) => { // 添加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#3f51b5' }}>
                    <SensorsIcon /> {/* 使用传感器图标 */}
                  </Avatar>
                </ListItemIcon>
              </Grid>
              <Grid item xs={10} paddingLeft={2}>
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 125 ? 'grey':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </ListItem>
      </div>
    );
};
  
function SensorCard({ sensorId, data, lastUpdated, handleChartClickOpen }) {
const [timeSinceUpdate, setTimeSinceUpdate] = useState(0);

useEffect(() => {
    const interval = setInterval(() => {
    setTimeSinceUpdate(Math.floor((Date.now() - lastUpdated) / 1000));
    }, 1000);

    return () => clearInterval(interval);
}, [lastUpdated]);

return (
    <Grid item xs={12} sm={12} md={6}>
    <Paper elevation={3} style={{ padding: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Name:</Typography>
            <Typography variant="body1" noWrap>{data.name}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>MAC:</Typography>
            <Typography variant="body1" noWrap>{sensorId.toUpperCase()}</Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Uptime [s]:</Typography>
            <Typography variant="body1" noWrap>{data.scd.w}</Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>WIFI Runtime [s]:</Typography>
            <Typography variant="body1" noWrap>{data.sps.w}</Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Battery [V]:</Typography>
            <Typography variant="body1" noWrap pb={3}>{(data.scd.v / 1000).toFixed(3)}</Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>CO2 [ppm]:</Typography>
            <Typography variant="body1" noWrap>{data.scd["3"]}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Temperature-SCD [°C]:</Typography>
            <Typography variant="body1" noWrap>{(data.scd["4"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Humidity-SCD [%]:</Typography>
            <Typography variant="body1" noWrap pb={3}>{(data.scd["5"] / 100).toFixed(2)}</Typography>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>HCHO [ppb-1]:</Typography>
            <Typography variant="body1" noWrap>{data.sfa["3"]}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Temperature-SFA [°C]:</Typography>
            <Typography variant="body1" noWrap>{(data.sfa["4"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Humidity-SFA [%]:</Typography>
            <Typography variant="body1" noWrap pb={3}>{(data.sfa["5"] / 100).toFixed(2)}</Typography>
        </div>


        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>PM 1.0 [µg/m³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["0"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>PM 2.5 [µg/m³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["1"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>PM 4.0 [µg/m³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["2"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>PM 10.0 [µg/m³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["3"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>NC 0.5 [#/cm³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["4"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>NC 1.0 [#/cm³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["5"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>NC 2.5 [#/cm³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["6"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>NC 4.0 [#/cm³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["7"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>NC 10.0 [#/cm³]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["8"] / 100).toFixed(2)}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap>Typical particle size [µm]:</Typography>
            <Typography variant="body1" noWrap>{(data.sps["9"] / 100).toFixed(2)}</Typography>
        </div>
        </div>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} pt={2} borderTop="1px solid #e0e0e0">
        <Typography variant="body1" noWrap style={{ color: timeSinceUpdate > 125 ? 'red' : 'inherit', visibility: timeSinceUpdate === 0 ? 'hidden' : 'visible' }}>
            Updated: {timeSinceUpdate} seconds ago
        </Typography>
        <Button variant="contained" onClick={handleChartClickOpen} style={{backgroundColor: '#999c91'}}>
            History
        </Button>
        </Box>
    </Paper>
    </Grid>
);
}

const Dashboard = ({userData, notification}) => {

  const location = useLocation(); // 使用 useLocation 钩子
  const state = location.state;

  const [sensorData, setSensorData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [selectedSensorId, setSelectedSensorId] = useState(''); // 新增状态以跟踪选中的传感器ID
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [buttonState, setButtonState] = useState('online');
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [openChart, setopenChart] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (state?.notification){
        setSearchTerm(state?.notification ? state.notification : '');
        setButtonState(state?.notification ? 'offline' : 'offline');
        setDisplayedRecords(state?.notification ? Object.entries(sensorData).map(([sensorId]) => sensorId) : Object.entries(sensorData).map(([sensorId]) => sensorId));
    }
  }, [state?.notification]);
  
  const handleChartClickOpen = () => {
    setopenChart(true);
  };

  const handleChartClickClose = () => {
    setopenChart(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();
        if(sensorData.name){
          setSensorData(prevData => ({
            ...prevData,
            [sensorId]: { name: sensorData.name, sps: JSON.parse(sensorData.SPS),scd: JSON.parse(sensorData.SCD), sfa: JSON.parse(sensorData.SFA), ...sensorData } // 更新 SPS 数据
        }));
        setLastUpdated(prevData => ({
            ...prevData,
            [sensorId]: currentTime
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchLastRecord();
    const interval = setInterval(fetchLastRecord, 5000); // 每30秒调用一次 fetchLastRecord
    return () => clearInterval(interval); // 清除定时器
  }, []);

 const filteredRecords = displayedRecords ? (Object.entries(sensorData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)})):Object.entries(sensorData).filter(([sensorId]) => Object.entries(lastUpdated).filter(([_, time]) => Math.floor(Date.now() - time) < 125 * 1000).map(([sensorId,data]) => sensorId).includes(sensorId)).filter(([sensorId,data])=>{return data.name.includes(searchTerm)});
  return (
    <>
    {openChart && <HistoryChart sensorName={sensorData[selectedSensorId].name} sensorId={selectedSensorId} open={openChart} onClose={handleChartClickClose}/>}
      <CssBaseline />
      <Container ref={containerRef}>
          <Box my={4} mx="auto" display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start" maxWidth="75%">
            <Box flex={1} mr={2}>
            <TextField
                variant="outlined"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={(e) => (
                    setButtonState(null),
                    setSearchTerm(e.target.value)
                )}
                fullWidth // 修改为 fullWidth
                InputProps={{
                  endAdornment: (
                    searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchTerm('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                    )
                  ),
                }} // 添加清空按钮
              />
              
              <FixedSizeList
                height={375} // 设置列表高度
                itemSize={75} // 每个项的高度
                itemCount={filteredRecords.length} // 项的数量
                width="100%" // 列表宽度
              >
                {({ index, style }) => {
                  const sensorId = filteredRecords[index][0]; // 获取传感器ID
                  return (
                    <Row
                      style={style} 
                      onClick={() => {
                        setSelectedSensorId(sensorId);
                        handleDrawerOpen(); // 点击时打开 Drawer
                      }} 
                      className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                      data = {filteredRecords[index]}
                      lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                    />
                  );
                }}
              </FixedSizeList>
  
              <Box mt={2} Width="75%" display="flex" justifyContent="space-between">
                <Button 
                  variant="contained" 
                  color={buttonState === 'offline' ? '#3f51b5' : 'primary'} // 修改为根据状态改变颜色
                  onClick={() => {
                    if (buttonState === 'offline'){
                      setButtonState(null);
                      setDisplayedRecords(null);
                      return;
                    }else{
                      setButtonState('offline'); // 设置选中的状态
                      setDisplayedRecords(Object.entries(sensorData).map(([sensorId]) => sensorId));
                      return;
                    }
                  }} // 仅显示在线传感器
                  size="large" 
                  style={{ minWidth: '20px', backgroundColor: buttonState === 'offline' ? '#3f51b5' : '#999c91', marginRight: '100px' }}
                >
                  Offline
                </Button>
              </Box>
            </Box>
            {window.innerWidth >= 500 &&
            <Box flex={2}>
              {selectedSensorId && (
                <SensorCard 
                  key={selectedSensorId} 
                  sensorId={selectedSensorId} 
                  data={sensorData[selectedSensorId]} 
                  lastUpdated={lastUpdated[selectedSensorId]} 
                  handleChartClickOpen={handleChartClickOpen}
                />
              )}
            </Box>
            }
          </Box>
        </Container>
    </>
  );
};

export default Dashboard;