import React, { useRef, useEffect, useState } from 'react';
import { Snackbar , Avatar, Container, Typography, Grid, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Stack } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import { FixedSizeList } from "react-window";
import CusDataTable from '../components/CusDataTable';
import Alert from '@mui/material/Alert';

const Row = ({ data, className, style , onClick, lastUpdated }) => { // 添加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#3f51b5' }}>
                    <SensorsIcon /> {/* 使用传感器图标 */}
                  </Avatar>
                </ListItemIcon>
              </Grid>
              <Grid item xs={10} paddingLeft={2}>
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 125 ? 'grey':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </ListItem>
      </div>
    );
};
  
const Trends = ({userData}) => {
  const [sensorData, setSensorData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [selectedSensorId, setSelectedSensorId] = useState(); // 新增状态以跟踪选中的传感器ID
  const [buttonState, setButtonState] = useState("online");
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [display, setDisplay] = useState(false);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();
        if(sensorData.name){
          setSensorData(prevData => ({
            ...prevData,
          [sensorId]: { name: sensorData.name, sps: JSON.parse(sensorData.SPS),scd: JSON.parse(sensorData.SCD), ...sensorData } // 更新 SPS 数据
        }));
        setLastUpdated(prevData => ({
            ...prevData,
            [sensorId]: currentTime
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchLastRecord();
    const interval = setInterval(fetchLastRecord, 5000); // 每30秒调用一次 fetchLastRecord
    return () => clearInterval(interval); // 清除定时器
  }, []);

 const filteredRecords = (Object.entries(sensorData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)}))

  return (
    <>
      <CssBaseline />
      <Container ref={containerRef}>
          <Box my={4} mx="auto" display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start" maxWidth="100%">
            <Box flex={1} mr={2}>
                <Stack direction="row" spacing={2} paddingBottom={2} alignItems="center">
                  <TextField
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    variant="outlined"
                  />
                  <Typography>-</Typography>
                  <TextField
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    variant="outlined"
                  />
              </Stack>
              <TextField
                variant="outlined"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={(e) => (
                    setButtonState(null),
                    setSearchTerm(e.target.value)
                )}
                fullWidth // 修改为 fullWidth
              />
              
              <FixedSizeList
                height={375} // 设置列表高度
                itemSize={75} // 每个项的高度
                itemCount={filteredRecords.length} // 项的数量
                width="100%" // 列表宽度
              >
                {({ index, style }) => {
                  const sensorId = filteredRecords[index][0]; // 获取传感器ID
                  return (
                    <Row
                      style={style} 
                      onClick={() => {
                        setSelectedSensorId(sensorId);
                        handleDrawerOpen(); // 点击时打开 Drawer
                      }} 
                      className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                      data = {filteredRecords[index]}
                      lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                    />
                  );
                }}
              </FixedSizeList>
  
              <Box mt={2} Width="75%" display="flex" justifyContent="center">
                <Button
                  size="large" 
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setDisplay(!display);
                  }}
                >
                  GET TRENDS
                </Button>
              </Box>
            </Box>
            <Box flex={3}>
                <CusDataTable // 添加表格
                  sensorId={selectedSensorId}
                  startDate={startDate}
                  endDate={endDate}
                  sensorData={sensorData}
                  setSeverity={setSeverity}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  display={display}
                />
            </Box>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
          </Snackbar>
        </Container>
    </>
  );
};

export default Trends;