import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Select, MenuItem, ButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles'; 
import 'chart.js/auto';

const datalist = [
  ["scd",0,1000],
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ["sfa",0,1],
  ["sfa",1,100],
  ["sfa",2,100],
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",4,100],
  ["sps",5,100],
  ["sps",6,100],
  ["sps",7,100],
  ["sps",8,100],
  ["sps",9,100],
]

const dataLabels = [
  "Battery [V]",
  "Co2 [ppm]",
  "Temperature-SCD [°C]",
  "Humidity-SCD [%]",
  "HCHO [ppb]",
  "Temperature-SFA [°C]",
  "Humidity-SFA [%]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "NC 0.5 [#/cm³]",
  "NC 1.0 [#/cm³]",
  "NC 2.5 [#/cm³]",
  "NC 4.0 [#/cm³]",
  "NC 10.0 [#/cm³]",
  "Typical particle size [µm]"
];

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    maxWidth: '600px', // Default max width
    height: '200%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%', // Full width on small screens
      margin: '0', // Remove default margin
      height: '200%',
    },
  },
  dialogContent: {
    padding: '10px',
  },
}));

function HistoryChart({ sensorName,sensorId, open, onClose }) {
  const classes = useStyles(); // Use the styles
  const [chartData, setChartData] = useState(null);
  const [wssData, setWssData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataPeriod, setDataPeriod] = useState(1); // Default to 1H

  const updateChartData = (data, index) => {
    const timestamps = Object.keys(data[datalist[index][0]]);
    const interval = (dataPeriod === 1) ? Math.ceil(timestamps.length / 200) : (dataPeriod === 3) ? Math.ceil(timestamps.length / 150) : Math.ceil(timestamps.length / 100);

    const filteredTimestamps = timestamps.filter((_, i) => i % interval === 0);
    const values = filteredTimestamps.map(timestamp=> data[datalist[index][0]][timestamp][datalist[index][1]] / (datalist[index][2]))

    setChartData({
            labels: filteredTimestamps.map(ts => (dataPeriod === 1) ? new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : new Date(ts).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })),
      datasets: [
        {
          label: dataLabels[index],
          data: values,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    });
  };

  const handleSelectChange = (event) => {
    const index = event.target.value;
    
    setSelectedIndex(index);
    if (Object.keys(wssData).length > 0) {
      updateChartData(wssData, index);
    } else {
      setChartData(null);
    }
  };

  const handlePeriodChange = (period) => {
    setDataPeriod(period);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const currentTime = new Date().getTime();
        const starttTime = new Date().getTime() - (dataPeriod * 3600 * 1000);
        const url = "/api/history?repeator_id=" + sensorId + "&start_time=" + starttTime + "&end_time=" + currentTime;
        const response = await fetch(url);
        const data = await response.json();
        console.log(data)
        setWssData(data);
        updateChartData(data,selectedIndex)
      } catch (error) {
        console.error('Error fetching last record:', error);
      }
    };
    fetchHistory();
  }, [dataPeriod,sensorId,selectedIndex]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" style={classes}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h6">
        {sensorName} History Data on {new Date().toLocaleDateString()}
        </Typography>
        <Typography variant="h6">
        ID : {sensorId.toUpperCase()}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'auto' }}>
        {chartData ? (
            <Line
              data={chartData}
              height={(window.innerWidth > 500 ? 200:400)}
              options={{
                scales: {
                  y: {
                    ticks: {
                      maxTicksLimit: 10 // Reduce the number of y-axis labels
                    }
                  }
                }
              }}
            />
        ) : (
          <Typography>Loading...</Typography>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
          <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group">
            <Button 
              onClick={() => handlePeriodChange(1)} 
              variant="contained"
              style={{ 
                backgroundColor: dataPeriod === 1 ? '#999c91' : '#363732', 
                color: '#fff', 
                border: 'none' // Remove border
              }} 
            >
              1H
            </Button>
            <Button 
              onClick={() => handlePeriodChange(3)} 
              variant="contained"
              style={{ 
                backgroundColor: dataPeriod === 3 ? '#999c91' : '#363732', 
                color: '#fff', 
                border: 'none' // Remove border
              }} 
            >
              3H
            </Button>
            <Button 
              onClick={() => handlePeriodChange(24)} 
              variant="contained"
              style={{ 
                backgroundColor: dataPeriod === 24 ? '#999c91' : '#363732', 
                color: '#fff', 
                border: 'none' // Remove border
              }} 
            >
              24H
            </Button>
          </ButtonGroup>
          <Select value={selectedIndex} onChange={handleSelectChange} style={{ marginTop: '10px' }}>
            {dataLabels.map((label, i) => (
              <MenuItem key={i} value={i}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default HistoryChart;